<template>
	<div class="container">
		<div style="margin: 30px auto;width: 80%;">
			<div style="font-size: 24px;text-align: center;line-height: 35px;height: 45px;">心电仪情绪数据</div>
			<el-card>
				<el-table id="out-table" :data="personnelData" border stripe :fit="true" style="width: 100%">
					<el-table-column :show-overflow-tooltip="true" width="80px" align="center" prop="nickname"
						label="姓名">
					</el-table-column>
					<el-table-column :show-overflow-tooltip="true" align="center" prop="bodyheight" label="身高">
					</el-table-column>
					<el-table-column :show-overflow-tooltip="true" align="center" prop="bodyweight" label="体重">
					</el-table-column>
					<el-table-column :show-overflow-tooltip="true" align="center" prop="age" label="年龄">
					</el-table-column>
					<el-table-column :show-overflow-tooltip="true" align="center" prop="emotion" label="心情指数">
						<template slot-scope="scope">
							<div>{{formatEmotion(scope.row.emotion)}}</div>
						</template>
					</el-table-column>
					<el-table-column :show-overflow-tooltip="true" align="center" prop="tired" label="疲劳指数">
						<template slot-scope="scope">
							<div>{{formatTired(scope.row.tired)}}</div>
						</template>
					</el-table-column>
					<el-table-column :show-overflow-tooltip="true" align="center" prop="stress" label="压力指数">
						<template slot-scope="scope">
							<div>{{formatStress(scope.row.stress)}}</div>
						</template>
					</el-table-column>
					<el-table-column :show-overflow-tooltip="true" align="center" prop="date_str" label="时间">
					</el-table-column>
					<el-table-column label="操作" align="center">
						<template slot-scope="scope">
							<el-button size="mini" type="primary" @click="preview(scope.row)">查看概览</el-button>
						</template>
					</el-table-column>
				</el-table>
			</el-card>
		</div>
		<el-dialog title="查看数据详情" :visible.sync="hzpreviewVisible" width="80%">
			<div class="chart-box" style="">
				<div id="hzpreview-chart" style="width: 100%;height: 100%;"></div>
			</div>
		</el-dialog>

	</div>
</template>

<script>
	import {
		xh_emotion_list,
		xh_emotion_detial
	} from "../api/monitoring.js"
	export default {
		data() {
			return {
				hzpreviewVisible: false,
				preview_chart_hz: '',
				personnelData: [],
			};
		},
		created() {
			xh_emotion_list().then(res => {
				this.personnelData = res.data;
			})
		},
		methods: {
			preview(d) {
				this.hzpreviewVisible = true;
				xh_emotion_detial(d.imei).then(res => {
					this.previewHzChartAll(res.data)
				})
			},
			previewHzChartAll(data) {
				let emotionAll = [];
				let stressAll = [];
				let tiredAll = [];
				let dateAll = [];
				data.forEach(item => {
					emotionAll.push(item.emotion)
					stressAll.push(item.stress)
					tiredAll.push(item.tired)
					dateAll.push(item.date_str)
				})
				setInterval(()=>{
					
				},4000)
				let chartDom = document.getElementById('hzpreview-chart')
				if (this.preview_chart_hz != '') {
					this.preview_chart_hz.dispose()
				}
				this.preview_chart_hz = this.$echarts.init(chartDom);
				let option = {
					tooltip: {
						trigger: 'axis',
						axisPointer: {
							type: 'cross',
							crossStyle: {
								color: '#999'
							}
						}
					},
					legend: {
						data: ['心情', '压力', '疲劳']
					},
					dataZoom: [{
							startValue: dateAll[0]
						},
						{
							type: 'inside'
						}
					],
					toolbox: {
						right: 10,
						feature: {
							dataZoom: {
								yAxisIndex: 'none'
							},
							// restore: {},
							saveAsImage: {}
						}
					},
					xAxis: [{
						type: 'category',
						data: dateAll,
						axisPointer: {
							type: 'shadow'
						}
					}],
					yAxis: {
						splitNumber: 10,
						max: 100,
						type: 'value',
						splitLine: {
							show: true,
							lineStyle: {
								color: '#ccc'
							}
						}
					},
					series: [{
							name: '心情',
							type: 'line',
							barGap: 0,
							emphasis: {
								focus: 'series'
							},
							data: emotionAll
						},
						{
							name: '压力',
							type: 'line',
							emphasis: {
								focus: 'series'
							},
							data: stressAll
						},
						{
							name: '疲劳',
							type: 'line',
							emphasis: {
								focus: 'series'
							},
							data: tiredAll
						},
					]
				};
				this.preview_chart_hz.clear()
				this.preview_chart_hz.setOption(option);
			},
			formatEmotion(index) {
				if (index >= 0 && index <= 49) {
					return index + '(较差)'
				}
				if (index >= 50 && index < 70) {
					return index + '(一般)'
				}
				if (index >= 70 && index <= 100) {
					return index + '(愉悦)'
				}
			},
			formatStress(index) {
				if (index >= 0 && index < 50) {
					return index + '(放松)'
				}
				if (index >= 50 && index < 70) {
					return index + '(一般)'
				}
				if (index >= 70 && index <= 100) {
					return index + '(压力山大)'
				}
			},
			formatTired(index) {
				if (index >= 0 && index < 55) {
					return index + '(精力充沛)'
				}
				if (index >= 50 && index < 70) {
					return index + '(有点疲惫)'
				}
				if (index >= 70 && index <= 100) {
					return index + '(十分疲惫)'
				}
				return index;
			},
		}
	}
</script>

<style>
	.chart-box {
		width: 100%;
		height: 580px;
	}
</style>
